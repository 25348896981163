import React, { FC, useMemo } from 'react';

import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import FormPanel from './FormPanel';
import InitialConfigFormFields from './models/InitialConfigFormFields';
import SensorConfiguration from './sensorConfig/SensorConfiguration';
import NetworkConfigurationMapper from './deviceNetworkConfig/NetworkConfigurationMapper';
import Header from '../../components/header';
import {
  formulaValidatorQueryClient,
  iconsQueryClient,
  initialConfigQueryClient,
} from '../../clients/ReactQueryClients/ReactQueryClients';
import { ReduxState } from '../../reducers';
import { useToast } from '@thingslog/ui-components';
import { DeviceInitialConfig } from '@thingslog/repositories';
import { QueryKeys } from '@thingslog/queries';
import InitialConfigConverter from './helpers/InitialConfigConverter';

const SensorsAndNetworkConfig: FC<SensorsAndNetworkConfigProps> = () => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { deviceNumber } = useParams();
  const reduxCompanyId = useSelector((state: ReduxState) => state.company.id);
  const methods = useForm<InitialConfigFormFields>({ mode: 'onChange' });

  const { useDeviceInitialConfig, useUpdateDeviceInitialConfig } = useMemo(
    () => initialConfigQueryClient,
    []
  );

  const { useGetIcons } = useMemo(() => iconsQueryClient, []);

  const { validateFormula } = useMemo(() => formulaValidatorQueryClient, []);

  const deviceInitialConfigQuery = useDeviceInitialConfig(deviceNumber!, {
    enabled: deviceNumber !== undefined,
    refetchOnWindowFocus: false,
    onSuccess: (data: DeviceInitialConfig) => {
      const formFields = InitialConfigConverter.portsConfigToFormFields(data);
      methods.reset(formFields);
    },
  });

  const deviceInitialConfigMutation = useUpdateDeviceInitialConfig({
    onSuccess: () => {
      toast({ type: 'success', message: t('sensors_and_network_save_success') });
      queryClient.invalidateQueries([QueryKeys.DeviceInitialConfig]);
    },
    onError: (error: AxiosError) =>
      toast({
        type: 'error',
        message: error.response?.data.message || t('sensors_and_network_save_error'),
      }),
  });

  const deviceIconQuery = useGetIcons(deviceNumber!, null, reduxCompanyId, {
    enabled: deviceNumber !== undefined,
    refetchOnWindowFocus: false,
  });

  const onSubmit = useMemo(() => {
    return (data: InitialConfigFormFields): void => {
      deviceInitialConfigMutation.mutate({
        deviceNumber: deviceNumber!,
        body: InitialConfigConverter.formFieldsToPortsConfig(data),
      });
    };
  }, []);

  const onError = (errors: FieldErrors<InitialConfigFormFields>): void => {
    toast({
      type: 'error',
      message: t('sensors_and_network_save_error'),
    });
  };

  return (
    <Header>
      {deviceInitialConfigQuery.data && !deviceInitialConfigQuery.isFetching && (
        <FormProvider {...methods}>
          <form className="flex flex-col gap-10" onSubmit={methods.handleSubmit(onSubmit, onError)}>
            <div className="w-full flex justify-end gap-5 sticky top-16 z-50 -mb-7">
              <Button
                className="sticky"
                type="submit"
                variant="contained"
                disabled={!methods.formState.isValid}
              >
                {t('button_save')}
              </Button>
              <Button
                type="button"
                variant="contained"
                onClick={(): void =>
                  methods.reset(
                    InitialConfigConverter.portsConfigToFormFields(deviceInitialConfigQuery.data)
                  )
                }
              >
                {t('button_reset')}
              </Button>
            </div>
            <div className="grid grid-cols-3 gap-10">
              <FormPanel className="col-span-1 max-md:col-span-3" title="Device Details">
                <></>
              </FormPanel>
              <FormPanel
                className="col-span-2 max-md:col-span-3"
                title={t('sensors_and_network_config_network_config')}
              >
                <NetworkConfigurationMapper deviceType={deviceInitialConfigQuery.data['@type']} />
              </FormPanel>
            </div>

            <SensorConfiguration
              icons={deviceIconQuery.data ? deviceIconQuery.data.icons : []}
              validateFormula={validateFormula}
            />
          </form>
        </FormProvider>
      )}
    </Header>
  );
};

interface SensorsAndNetworkConfigProps {}

export default SensorsAndNetworkConfig;
